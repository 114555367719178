import { useState, useEffect } from 'react'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { occupationDictionary } from '../../../constants'
import services from './services'

const useSelectOccupation = () => {
  const { setIsLoading } = useProposal()
  const { changeLeadData } = useLead()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-fonte-renda' })
  }, [])

  useEffect(() => {
    const getData = async () => {
      setGetLoading(true)

      const { previous_step } =
        await services.getOccupation()
      changeLeadData({
        previousStep: previous_step
      })

      setGetLoading(false)
    }

    getData()
    console.log('occupation mounted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading])

  const onSubmit = async (payload) => {
    setSubmitLoading(true)

    const { occupation } = payload

    const requestBody = {
      occupation
    }

    analytics.track('buttonClick', {
      currentPage: 'formulario-fonte-renda',
      detail: 'click-step-fonte-renda',
      customLayer: {
        description: `fonte-renda-${occupationDictionary[occupation]}`
      }
    })

    return services
      .putOccupation(requestBody)
      .then(({ next_step }) => {
        changeLeadData(requestBody)
        goTo(next_step)
      })
      .finally(() => setSubmitLoading(false))
  }

  return { onSubmit, submitLoading, getLoading }
}

export default useSelectOccupation
